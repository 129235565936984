export default {
  siteTitle: 'Get bonus',
  blogerName: 'FULLBET',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://youtube.com/@fullbet'
    },
    {
      name: 'telegram',
      url: 'https://t.me/+86SxWyq0mEMyMTVh'
    }
  ],
  projects: [
    {
      name: 'starda',
      url: 'https://stardacasino.life/c7686e723',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c05cd1fac',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/cfb62fb27',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c97090c9f',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c3d010c3d',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/cec023a6f',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>FULLBET</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'FULLBET',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado'
};
